import { bool, oneOf, string } from "prop-types";
import React from "react";

import classnames from "~utils/classnames";

import icons from "./icons";
import classes from "./NewIcon.module.scss";

/**
 * The original Icon component uses svg files copied from Figma. These files
 * have very different viewboxes and sizes making it hard to reuse consistently.
 * The svg files in this component are direct copies from the FontAwesome set.
 */
const NewIcon = ({ type, focusable, className }) => {
  const Component = icons[type];

  if (!Component) {
    throw Error(`No icon found for type: ${type}`);
  }

  return (
    <Component
      className={classnames([className, classes.icon])}
      focusable={focusable ? "true" : "false"}
    />
  );
};

NewIcon.propTypes = {
  type: oneOf(Object.keys(icons)).isRequired,
  focusable: bool,
  className: string,
};

export default NewIcon;
