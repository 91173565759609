const favouriteRoutes = [
  {
    locale: "nl",
    path: "/favorieten",
    destination: "/favourites",
  },
  {
    locale: "en",
    path: "/favourites",
    destination: "/favourites",
  },
];

const favouriteShareRoutes = [
  {
    locale: "nl",
    path: "/favorieten/deel",
    destination: "/favourites/share",
  },
  {
    locale: "en",
    path: "/favourites/share",
    destination: "/favourites/share",
  },
];

const searchRoutes = [
  {
    locale: "nl",
    path: "/zoeken",
    destination: "/search",
  },
  {
    locale: "de",
    path: "/suchen",
    destination: "/search",
  },
  {
    locale: "en",
    path: "/search",
    destination: "/search",
  },
  {
    locale: "es",
    path: "/buscar",
    destination: "/search",
  },
  {
    locale: "fr",
    path: "/chercher",
    destination: "/search",
  },
  {
    locale: "it",
    path: "/cercare",
    destination: "/search",
  },
  {
    locale: "pt",
    path: "/procurar",
    destination: "/search",
  },
];

module.exports = { favouriteRoutes, favouriteShareRoutes, searchRoutes };
