import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useContext, useEffect, useRef, useState } from "react";

import { favouriteRoutes } from "~config/routing";
import { FavouriteItemsContext } from "~contexts/FavouriteItemsContext";

import classes from "./FavouriteButton.module.scss";

const FavouriteButton = () => {
  const { t } = useTranslation();
  const { favouriteItemIds, onFavouritesChange } = useContext(
    FavouriteItemsContext,
  );
  const numberOfFavourites = favouriteItemIds.length;
  const [animate, setAnimate] = useState(false);
  const iconRef = useRef(null);
  const { locale } = useRouter();

  const favouritesRoute = favouriteRoutes.find(
    (route) => route.locale === locale,
  );

  useEffect(() => {
    const removeListener = onFavouritesChange((newIdState, oldIdState) => {
      if (newIdState.length > oldIdState.length) {
        setAnimate(true);
      }
    });
    return () => {
      removeListener();
      setAnimate(false);
    };
  }, [onFavouritesChange]);

  useEffect(() => {
    const iconEl = iconRef.current;

    if (!iconEl) {
      return;
    }

    const reset = () => {
      setAnimate(false);
    };

    iconEl.addEventListener("animationend", reset);

    return () => {
      iconEl.removeEventListener("animationend", reset);
    };
  }, []);

  if (!favouritesRoute) {
    return;
  }

  return (
    <Link
      href={favouritesRoute?.path ?? "/favourites"}
      title={t("favourites:favouritesTitle")}
      className={classes.favourite_button}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className={classes[`heart${animate ? "_animate" : ""}`]}
        ref={iconRef}
      >
        <path
          className={classes[`fill${animate ? "_animate" : ""}`]}
          d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
        />
        <path
          fill="black"
          d="M255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L255.1 96zM255.1 141.3L221.4 106.6C196.1 81.31 160 69.77 124.7 75.66C71.21 84.58 31.1 130.9 31.1 185.1V190.9C31.1 223.6 45.55 254.7 69.42 277L250.1 445.7C251.7 447.2 253.8 448 255.1 448C258.2 448 260.3 447.2 261.9 445.7L442.6 277C466.4 254.7 480 223.6 480 190.9V185.1C480 130.9 440.8 84.58 387.3 75.66C351.1 69.77 315.9 81.31 290.6 106.6L255.1 141.3z"
        />
      </svg>
      {numberOfFavourites > 0 && (
        <span className={classes.counter}>
          {numberOfFavourites > 9 ? "9+" : numberOfFavourites}
        </span>
      )}
    </Link>
  );
};

FavouriteButton.propTypes = {};

export default FavouriteButton;
